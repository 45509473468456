var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'atc-button',
    {
      'is-big': _vm.isFullScreen || _vm.isBubbleAndLargeScreen || _vm.isEmbedMobile,
      'carousel-fullscreen-horizontal-atc-button-wrapper-preview':
        _vm.isCarousel && _vm.isFullScreen && _vm.isHorizontalSwipe && _vm.isInPreview,
    },
  ],on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("left-arrow"),_c('div',{class:[
      'button-with-image',
      {
        'is-big': _vm.isFullScreen || _vm.isBubbleAndLargeScreen || _vm.isEmbedMobile,
        'is-inline':
          !_vm.isFullScreen && !_vm.isBubbleAndLargeScreen && !_vm.isEmbedMobile,
        'button-with-img-has-arrows':
          (_vm.isFullScreen && _vm.hasArrows) ||
          (_vm.isBubbleAndLargeScreen && _vm.hasArrows) ||
          (_vm.isEmbedMobile && _vm.hasArrows),
        'inline-button-with-img-has-arrows':
          !_vm.isFullScreen &&
          _vm.hasArrows &&
          !_vm.isBubbleAndLargeScreen &&
          !_vm.isEmbedMobile,
      },
    ],style:(_vm.buttonWithImageStyles)},[_c('div',{staticClass:"product-image",class:{
        'inline-product-image': !_vm.isFullScreen,
        'image-with-margin':
          _vm.format.productCardOptions &&
          _vm.format.productCardOptions.style === 'margin',
      }},[_c('img',{staticClass:"product-image",class:{
          'inline-product-image': !_vm.isFullScreen,
          'image-with-margin':
            _vm.isFullScreen &&
            _vm.format.productCardOptions &&
            _vm.format.productCardOptions.style === 'margin',
        },attrs:{"src":_vm.resizedImageUrl || _vm.product.imageUrl,"alt":"product","draggable":"false"}}),(!_vm.product.imageUrl)?_c('BagIcon'):_vm._e()],1),(_vm.isFullScreen || _vm.isBubbleAndLargeScreen || _vm.isEmbedMobile)?_c('div',{staticClass:"product-information",class:{ 'product-information-has-arrows': _vm.hasArrows }},[_c('p',{staticClass:"name",style:(_vm.textStyles)},[_vm._v(" "+_vm._s(_vm.decodeText(_vm.product.name) || _vm.$t("player.atcButton.yourProduct"))+" ")]),_c('p',{staticClass:"price",style:(_vm.textStyles)},[_vm._v(" "+_vm._s(_vm.displayCurrency(_vm.currency, _vm.product.variants[0].price))+" ")])]):_vm._e()]),_vm._t("right-arrow")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }